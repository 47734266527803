<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="10"
    :search="search"
    :loading="loading"
    :loading-text="'Chargement en cours...'"
    item-key="_id"
    multi-sort
  />
</template>

<script>
export default {
  name: 'PartTypeList',
  components: {},
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headers () {
      const baseHeaders = [
        {
          text: 'Nom',
          align: 'center',
          value: 'name',
        },
      ]
      return baseHeaders
    },
    items () {
      return this.value
    },
  },
}
</script>
